exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-about-us-js": () => import("./../../../src/templates/about-us/about-us.js" /* webpackChunkName: "component---src-templates-about-us-about-us-js" */),
  "component---src-templates-e-commerce-category-index-tsx": () => import("./../../../src/templates/e-commerce/category/index.tsx" /* webpackChunkName: "component---src-templates-e-commerce-category-index-tsx" */),
  "component---src-templates-e-commerce-checkout-index-js": () => import("./../../../src/templates/e-commerce/checkout/index.js" /* webpackChunkName: "component---src-templates-e-commerce-checkout-index-js" */),
  "component---src-templates-e-commerce-contact-js": () => import("./../../../src/templates/e-commerce/contact.js" /* webpackChunkName: "component---src-templates-e-commerce-contact-js" */),
  "component---src-templates-e-commerce-index-js": () => import("./../../../src/templates/e-commerce/index.js" /* webpackChunkName: "component---src-templates-e-commerce-index-js" */),
  "component---src-templates-e-commerce-product-index-tsx": () => import("./../../../src/templates/e-commerce/product/index.tsx" /* webpackChunkName: "component---src-templates-e-commerce-product-index-tsx" */),
  "component---src-templates-e-commerce-shopping-cart-index-tsx": () => import("./../../../src/templates/e-commerce/shoppingCart/index.tsx" /* webpackChunkName: "component---src-templates-e-commerce-shopping-cart-index-tsx" */),
  "component---src-templates-e-commerce-thank-you-index-js": () => import("./../../../src/templates/e-commerce/thankYou/index.js" /* webpackChunkName: "component---src-templates-e-commerce-thank-you-index-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legal/template.js" /* webpackChunkName: "component---src-templates-legal-template-js" */)
}

